const state = {
  message: {
    variant: null,
    text: null,
  },
};

const mutations = {
  SET_MESSAGE(state, payload) {
    state.message.text = payload.text;
    state.message.variant = payload.variant;
  },
};

const actions = {
  setMessage({ commit }, payload) {
    commit("SET_MESSAGE", payload);
  },
};

const getters = {
  message: (state) => state.message,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
