import client from "@/api/client";

const SET_LOADING = "SET_LOADING";

const state = {
  isLoading: false,
};

const mutations = {
  [SET_LOADING](state, payload) {
    state.isLoading = payload.isLoading;
  },
};

const actions = {
  async refreshingCache({ commit }) {
    commit(SET_LOADING, { isLoading: true });
    try {
      const response = await client.post("/refresh");
      console.log("Response:", response.data);
    } catch (error) {
      console.error("Error refreshing cache:", error);
      commit(
        "messages/SET_MESSAGE",
        {
          text: "Something went wrong, please try again. If the error persists, contact the admin.",
          variant: "danger",
        },
        { root: true },
      );
      setTimeout(() => {
        commit(
          "messages/SET_MESSAGE",
          { message: null, type: null },
          { root: true },
        );
      }, 30000);
      throw error;
    } finally {
      commit(SET_LOADING, { isLoading: false });
    }
  },
};

const getters = {
  isLoading: (state) => state.isLoading,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
