import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/";
import VueI18n from "vue-i18n";
import EN from "./locales/EN.json";
import PT from "./locales/PT.json";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";

const messages = {
  en: EN,
  "en-US": EN,
  pt: PT,
  "pt-BR": PT,
};

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueI18n);
Vue.config.productionTip = false;

const i18n = new VueI18n({
  locale: window.navigator.language ?? "en-US",
  messages,
});

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
