import client from "@/api/client";
import { convertDateToLocal } from "@/utils/date";
import router from "@/router";
import { removeItem } from "@/services/localStorage";

const SET_SCHEDULE = "SET_SCHEDULE";
const SET_LOADING = "SET_LOADING";
const SET_AREA = "SET_AREA";
const SET_STACK = "SET_STACK";
const SET_WEEK = "SET_WEEK";
const RESET_STATE = "RESET_STATE";
const SET_EXPIRE_MESSAGE = "SET_EXPIRE_MESSAGE";

const state = {
  slots: [],
  isLoading: false,
  area: null,
  stack: null,
  week: 0,
  expireMessage: null,
  missing_as: {
    masters: [],
    questers: [],
  },
};

const mutations = {
  [SET_SCHEDULE](state, payload) {
    state.slots.push(...payload.slots);
    state.missing_as = payload.missing_as;
  },
  [SET_LOADING](state, payload) {
    state.isLoading = payload.isLoading;
  },
  [SET_AREA](state, payload) {
    state.area = payload;
  },
  [SET_STACK](state, payload) {
    state.stack = payload;
  },
  [SET_WEEK](state, payload) {
    state.week = payload;
  },
  [RESET_STATE](state, initialState) {
    Object.assign(state, initialState);
  },
  [SET_EXPIRE_MESSAGE](state) {
    state.expireMessage = null;
  },
};

const actions = {
  setExpireMessage({ commit }) {
    commit(
      "messages/SET_MESSAGE",
      { message: null, type: null },
      { root: true },
    );
  },
  async fetchSchedules({ commit }, headers) {
    commit(SET_LOADING, { isLoading: true });
    try {
      const response = await client.get("/api/schedules", { headers });

      if (response?.data) {
        let { slots, missing_as } = {
          slots: response.data.slots,
          missing_as: {
            masters: response.data.missing_as.filter(
              ({ status }) => status === "Master",
            ),
            questers: response.data.missing_as.filter(
              ({ status }) => status === "Quester",
            ),
          },
        };

        if (slots && slots.length) {
          slots = slots.map(({ timeSlots, ...rest }) => ({
            ...rest,
            timeSlots: convertDateToLocal(timeSlots),
          }));

          commit(SET_SCHEDULE, { slots, missing_as });
        } else {
          commit(
            "messages/SET_MESSAGE",
            {
              text: "store.modules.schedules.messages.noSlotsFound",
              variant: "info",
            },
            { root: true },
          );
        }
      } else {
        commit(RESET_STATE, {
          slots: [],
          area: null,
          stack: null,
          missing_as: {
            masters: [],
            questers: [],
          },
        });
        commit(
          "messages/SET_MESSAGE",
          {
            text: "store.modules.schedules.messages.noSlotsFound",
            variant: "info",
          },
          { root: true },
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      commit(
        "messages/SET_MESSAGE",
        {
          text: "store.modules.schedules.messages.somethingWentWrong",
          variant: "danger",
        },
        { root: true },
      );
      setTimeout(() => {
        commit(
          "messages/SET_MESSAGE",
          { message: null, type: null },
          { root: true },
        );
      }, 30000);

      const { response } = error;
      if (response && (response.status === 401 || response.status === 400)) {
        commit(
          "messages/SET_MESSAGE",
          {
            text: "store.modules.schedules.messages.tokenHasExpired",
            variant: "warning",
          },
          { root: true },
        );
        const { redirectTo } = response.data;
        if (redirectTo) {
          removeItem("credential");
          setTimeout(() => {
            commit(
              "messages/SET_MESSAGE",
              { message: null, type: null },
              { root: true },
            );
          }, 30000);
        }
        setTimeout(() => {
          router.replace("/signin");
          commit(
            "messages/SET_MESSAGE",
            { message: null, type: null },
            { root: true },
          );
        }, 10000);
      }
      throw error;
    } finally {
      commit(SET_LOADING, { isLoading: false });
    }
  },

  setArea({ commit }, payload) {
    commit(SET_AREA, payload);
  },
  setStack({ commit }, payload) {
    commit(SET_STACK, payload);
  },
  setWeek({ commit }, payload) {
    commit(SET_WEEK, payload);
  },
  resetState({ commit }) {
    commit(RESET_STATE, {
      slots: [],
      area: null,
      stack: null,
      missing_as: {
        masters: [],
        questers: [],
      },
    });
  },
};

const getters = {
  slots: (state) => state.slots,
  isLoading: (state) => state.isLoading,
  currentArea: (state) => state.area,
  currentStack: (state) => state.stack,
  currentWeek: (state) => state.week,
  missing_as: (state) => state.missing_as,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
