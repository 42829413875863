import client from "@/api/client";
import createPersistedState from "vuex-persistedstate";

const SET_LOADING = "SET_LOADING";
const SET_AREAS_AND_STACKS = "SET_AREAS_AND_STACKS";

const state = {
  isLoading: false,
  areasAndStacksMap: new Map(
    JSON.parse(localStorage.getItem("areasAndStacksMap")),
  ),
};

const mutations = {
  [SET_LOADING](state, payload) {
    state.isLoading = payload.isLoading;
  },
  [SET_AREAS_AND_STACKS](state, payload) {
    state.areasAndStacksMap = payload.areasAndStacksMap;
    localStorage.setItem(
      "areasAndStacksMap",
      JSON.stringify(Array.from(payload.areasAndStacksMap.entries())),
    );
  },
};

const actions = {
  async fetchAreasAndStacks({ commit }) {
    commit(SET_LOADING, { isLoading: true });

    try {
      const response = await client.get("/stacks");

      if (response.data) {
        const areasAndStacksMap = new Map();

        for (const item of response.data) {
          const [area, , stack] = item;

          if (!areasAndStacksMap.has(area)) {
            // If the area is not in the map, create an array to store multiple stacks
            areasAndStacksMap.set(area, [stack]);
          } else {
            // If the area is already in the map, append the stack to the existing array
            areasAndStacksMap.get(area).push(stack);
          }
        }

        commit(SET_AREAS_AND_STACKS, { areasAndStacksMap });
      }
    } catch (error) {
      // Handle error
      console.error(error);
    } finally {
      commit(SET_LOADING, { isLoading: false });
    }
  },
};

const getters = {
  isLoading: (state) => state.isLoading,
  areasAndStacksMap: (state) => state.areasAndStacksMap,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
  plugins: [createPersistedState()],
};
