import axios from "axios";
import { getItem } from "@/services/localStorage";
import { API_APP_URL } from "@/constants/env";

const client = axios.create({
  baseURL: API_APP_URL,
  timeout: 0,
});

// interceptor
client.interceptors.request.use(
  (config) => {
    const token = getItem("credential");

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export default client;
