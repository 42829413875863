import Vue from "vue";
import Vuex from "vuex";
import refresh from "./modules/refresh";
import schedules from "./modules/schedules";
import messages from "./modules/messages";
import stacksAndAreas from "./modules/stacksAndAreas";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    refresh,
    schedules,
    messages,
    stacksAndAreas,
  },
});
