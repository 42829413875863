import { BASE_URL } from "@/constants/env";
import { getItem } from "@/services/localStorage";
import Vue from "vue";
import VueRouter from "vue-router";
const LazyHomeView = () => import("@/views/HomeView.vue");
const LazySignInView = () => import("@/views/SignInView.vue");

const routes = [
  {
    path: "/",
    name: "home",
    component: LazyHomeView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/signin",
    name: "signin",
    component: LazySignInView,
    meta: {
      requiresAuth: false,
    },
  },
  { path: "*", redirect: "/" },
];

const router = new VueRouter({
  mode: "history",
  base: BASE_URL,
  routes,
});

router.beforeEach((to, _from, next) => {
  const credential = getItem("credential");
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  if (to.path === "/signin" && credential) {
    return next("/");
  }
  if (requiresAuth && !credential) {
    return next("/signin");
  }
  return next();
});

Vue.use(VueRouter);

export default router;
